import React from 'react';
import { useLocation } from "@reach/router"
import { defaultFacets, normalizeItems, sortOptions } from '../../config/search';
import { Search, SearchSidebar, SearchContent } from "@civicactions/data-catalog-components";
import Layout from "../../components/Layout";
import config from "../../assets/config";
import '../search/search.scss';
import { Helmet } from "react-helmet";

const SearchTemplate = ({path}) => {
  const location = useLocation();
  return (
    <Layout title="Search" breadcrumbs={[{text:"Datasets", path:"/search"}]}>
      <Helmet>
        <meta name="robots" content="noindex, nofollow" />
        <meta name="googlebot" content="noindex, nofollow" />
      </Helmet>
      <div className={`dc-page ${config.container}`}>
        <h1>Datasets</h1>
        <Search
          searchEndpoint={`${process.env.REACT_APP_ROOT_URL}/search`}
          defaultFacets={defaultFacets}
          sortOptions={sortOptions}
          setSearchUrl={true}
          path={path}
          location={location}
          normalize={normalizeItems}
        >
          <div className="row">
            <SearchSidebar />
            <SearchContent />
          </div>
        </Search>
      </div>
    </Layout>
  );
}

export default SearchTemplate;

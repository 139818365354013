import React, { useEffect, useState } from "react";
import Layout from "../../components/Layout";
import config from "../../assets/config";
import { Text } from "@civicactions/data-catalog-components";
import axios from "axios";
import { Facebook } from 'react-content-loader';
import { Helmet } from "react-helmet";

const Page = ({ location }) => {
  const pathname = location.pathname;

  const [page, setPage] = useState({ path: '', data: null })
  const [loaded, setloading] = useState(0)

  useEffect(() => {
    async function getPage() {
      const { data: url } = await axios.get(`${process.env.REACT_APP_D9_URL}/local-path?alias=${pathname}`);
      
      if (pathname === url.path) {
        setPage({ path: pathname, data: 0 });
        setloading(1);
        return;
      }

      const id = url.path.split('/').slice(2);
      const { data } = await axios.get(`${process.env.REACT_APP_D9_URL}/node/${id}?_format=json`);

      setPage({ path: pathname, data: data });
      setloading(1);
    }

    if (page.path !== pathname) {
      getPage();
    }
  }, [page, pathname]);

  return (
    <Layout title="About">
      <div className={`dc-page ${config.container}`}>
        {page.data ? (
          <>
            <h1>{page.data.title[0].value}</h1>
            <div className="dc-page-content row">
              <div className="col-md-12 col-sm-12">
                <Text value={page.data.body[0].processed} />
              </div>
            </div>
          </>
        ) : (
          <>
            {loaded && (
              <Helmet>
                <title>Page Not Found</title>
                <meta name="robots" content="noindex, nofollow" />
                <meta name="googlebot" content="noindex, nofollow" />
                <meta name="status" content="404" />
              </Helmet>
            )}
            {loaded ? <h1>Page not found.</h1> : <Facebook />}
          </>
        )}
      </div>
    </Layout>
  );
};

export default Page;
